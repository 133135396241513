<template>
  <menu-layout :value="1">
    <gaudy-title label="公需科目"></gaudy-title>
    <div class="specialized-top">
      <div class="top-one">
        <div class="top-one-top">
          <div class="top-one-top-left">学习进度</div>
          <div class="top-one-top-right">
            {{ staticsInfo.zongXueBaiFenBai }}%
          </div>
        </div>
        <div>
          <el-progress
            :text-inside="true"
            define-back-color="rgba(186, 41, 38, 0.1)"
            color="#B50413"
            :show-text="false"
            :stroke-width="44"
            :percentage="staticsInfo.zongXueBaiFenBai"
          ></el-progress>
        </div>
      </div>

      <div class="top-tow">
        <div class="top-tow-top">学习时间</div>
        <div class="top-tow-center">
          {{ staticsInfo.startTime }} ~ {{ staticsInfo.endTime }}
        </div>
        <div class="top-tow-bottom">进行中</div>
      </div>

      <div class="top-three">
        <div class="top-three-top">课程统计</div>
        <div class="top-three-bottom">
          <div class="top-three-bottom-card">
            <div class="value">{{ staticsInfo.zongXue }}</div>
            <div class="text">已获总学时</div>
          </div>
          <div class="top-three-bottom-card">
            <div class="value">{{ staticsInfo.zongTime }}</div>
            <div class="text">学习总时长</div>
          </div>
        </div>
      </div>
    </div>
    <div class="body_contents">
      <div class="tabs">
        <div class="tabsType">
          <split-tab :list="selectList3" @click="tabsTypeChange" />
        </div>
        <div class="tabsList">
          <radio-tab :list="selectList2" @click="radioClick"> </radio-tab>
          <capsule-tab :list="selectList" @click="statusClick"> </capsule-tab>
        </div>
      </div>
      <div class="public-course">
        <div style="margin: 15px 0px" v-if="courseParams.speciesEnum === 0">
          <div class="playerList" v-if="informationList2.length > 0">
            <div
              class="play-card"
              v-for="(item, index) in informationList2"
              :key="index"
              @click="
                keepAliveNavigation(
                  `/openPlayer?courseContId=${item.courseContId}`,
                  {},
                  true,
                  false
                )
              "
            >
              <div
                class="cover"
                :style="{ backgroundImage: `url(${item.videoImg})` }"
              >
                <div
                  class="status-tags"
                  :style="{
                    background: item.isWanCheng
                      ? item.isTongGuo
                        ? '#549A54'
                        : '#E6A23C'
                      : '#B50413',
                  }"
                >
                  {{
                    item.isWanCheng
                      ? item.isTongGuo
                        ? "完成"
                        : "待考试"
                      : "未完成"
                  }}
                </div>
              </div>
              <div class="play-card-box">
                <div class="play-card-title" :title="item.courseContName">
                  {{ item.courseContName }}
                </div>
                <div class="play-card-psn">
                  <div class="play-card-psn" style="margin: 0px">
                    时长：{{ item.totalSeconds }}秒
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-empty v-else />
          <div class="information-box-list-pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="courseParams.pageNo"
              :page-size="courseParams.pageSize"
              layout="jumper, prev, pager, next "
              :total="courseParams.total"
            >
            </el-pagination>
          </div>
        </div>
        <div v-if="courseParams.speciesEnum === 1">
          <div class="information" v-if="informationList.length > 0">
            <div
              class="information-card"
              v-for="(item, index) in informationList"
              :key="index"
              @click="
                keepAliveNavigation(
                  `/imageAndText`,
                  { courseContId: item.courseContId },
                  true,
                  false
                )
              "
            >
              <div class="information-card-title">
                <div
                  class="status"
                  :style="{
                    background: item.isWanCheng
                      ? item.isTongGuo
                        ? '#549A54'
                        : '#E6A23C'
                      : '#B50413',
                  }"
                >
                  {{
                    item.isWanCheng
                      ? item.isTongGuo
                        ? "完成"
                        : "待考试"
                      : "未完成"
                  }}
                </div>
                <div class="title">{{ item.courseContName }}</div>
              </div>
              <div class="information-card-abstract">
                <div class="text">
                  {{ item.introduction }}
                </div>
              </div>
              <div class="information-card-bottom">
                <div class="wordsNum-box">
                  <svg-icon class-name="wordsNum" icon="icon-zishu" />
                  <div>{{ item.wordCount }}字</div>
                </div>
                <div class="information-card-bottom-time">
                  预计阅读时长：{{ item.totalSeconds }} 秒
                </div>
              </div>
            </div>
          </div>
          <el-empty v-else />
          <div class="information-box-list-pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="courseParams.pageNo"
              :page-size="courseParams.pageSize"
              layout="jumper, prev, pager, next "
              :total="courseParams.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </menu-layout>
</template>
<script>
import GaudyTitle from "@/components/gaudyTitle.vue";
import RadioTab from "@/components/radioTab.vue";
import SvgIcon from "@/components/svgIcon.vue";
import MenuLayout from "@/components/MenuLayout.vue";
import CapsuleTab from "@/components/capsuleTab.vue";
import SplitTab from "@/components/splitTab.vue";
import * as storage from "@/utils/storage";
import {
  getCourseList,
  getPublicStatistics,
} from "@/api/publicDemandController";

export default {
  name: "home",
  components: {
    SplitTab,
    CapsuleTab,
    MenuLayout,
    SvgIcon,
    RadioTab,
    GaudyTitle,
  },
  data() {
    return {
      staticsInfo: {},
      selectList: [
        {
          label: "全部",
          value: 2,
        },
        {
          label: "未完成",
          value: 0,
        },
        {
          label: "待考试",
          value: 3,
        },
        {
          label: "已完成",
          value: 1,
        },
      ],
      selectList2: [
        {
          label: "思想政治",
          value: 1,
        },
        {
          label: "法律法规",
          value: 2,
        },
        {
          label: "职业道德",
          value: 3,
        },
        {
          label: "诚信自律",
          value: 4,
        },
      ],
      selectList3: [
        {
          label: "视频课程",
          value: 0,
        },
        {
          label: "文章资讯",
          value: 1,
        },
      ],
      informationList: [],
      informationList2: [],
      courseParams: {
        pageNo: 1,
        pageSize: 8,
        speciesEnum: 0,
        classifyEnum: 1,
        statusEnum: 2,
        total: 0,
      },
    };
  },
  created() {
    // this.publicStatics();
    // this.courseList();
  },
  activated() {
    this.handleRefresh();
    // this.inspectRefresh();
  },
  methods: {
    // 获取公需课统计
    publicStatics() {
      const that = this;
      getPublicStatistics({ trainingPlanId: storage.getStore("yearId") }).then(
        (res) => {
          that.staticsInfo = res.data;
          if (that.staticsInfo.zongXueBaiFenBai) {
            that.staticsInfo.zongXueBaiFenBai = Number(
              that.staticsInfo.zongXueBaiFenBai
            );
          }
        }
      );
    },
    // 获取公需课累表
    courseList() {
      const that = this;
      getCourseList({
        trainingPlanId: storage.getStore("yearId"),
        ...that.courseParams,
      }).then((res) => {
        that.courseParams.total = res.data.totalCount;
        that.courseParams.pageNo = res.data.pageNo;
        if (that.courseParams.speciesEnum == 0) {
          // 视频
          that.informationList2 = res.data.data.map((item) => {
            if (item.videoUrl) {
              const url = JSON.parse(item.videoUrl)[0].url || "";
              item.videoImg = url
                .replace(".mp4", ".jpg")
                .replace(".MP4", ".jpg");
            } else {
              item.videoImg = "";
            }
            return item;
          });
        } else {
          // 文章
          that.informationList = res.data.data;
        }
      });
    },
    // 切换小类
    radioClick(data) {
      this.courseParams.classifyEnum = data.value;
      this.courseParams.pageNo = 1;
      this.courseList();
    },
    // 切换状态
    statusClick(data) {
      this.courseParams.statusEnum = data.value;
      this.courseParams.pageNo = 1;
      this.courseList();
    },
    // 切换类型
    tabsTypeChange(e) {
      this.courseParams.speciesEnum = e.value;
      this.courseParams.pageNo = 1;
      this.courseList();
    },
    // 分页查询
    handleCurrentChange(e) {
      this.courseParams.pageNo = e;
      this.courseList();
    },
    handleRefresh() {
      console.log("我刷新咯");
      this.publicStatics();
      this.courseList();
    },
  },
};
</script>
<style scoped lang="scss">
.information-box-list-pagination {
  margin-top: 20px;
  text-align: right;
  /deep/ .active {
    background-color: #b40b1a !important;
  }
}
.pagex {
  margin-bottom: 25px;
}
.body_contents {
  width: 100%;
  .tabs {
    padding: 16px 24px;
    box-sizing: border-box;
    border: 1px solid #eceef1;
    background: #ffffff;
    margin-bottom: 5px;
    .tabsList {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
    }
  }
  .public-course {
    padding: 16px 24px;
    box-sizing: border-box;
    border: 1px solid #eceef1;
    background: #ffffff;
  }
}
.specialized-top {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 2fr;
  box-sizing: border-box;
  margin: 15px auto;

  .top-one {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    /deep/ .el-progress-bar__outer {
      border-radius: 16px !important;
    }
    /deep/ .el-progress-bar__inner {
      border-radius: 16px !important;
    }

    .top-one-top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;

      .top-one-top-left {
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;
        font-feature-settings: "kern" on;
      }

      .top-one-top-right {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0px;

        font-feature-settings: "kern" on;
        color: #ba2926;
      }
    }
  }

  .top-tow {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;

    .top-tow-top {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      font-feature-settings: "kern" on;
    }

    .top-tow-center {
      font-family: Source Han Sans;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      margin: 8px auto;
      white-space: nowrap;
      font-feature-settings: "kern" on;
      /* 亮色模式/字体色/正文 */
      color: #505363;
    }

    .top-tow-bottom {
      font-family: Source Han Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      width: max-content;
      font-feature-settings: "kern" on;
      color: #b50413;
      box-sizing: border-box;
      border: 1px solid #b50413;
      padding: 2px 6px;
    }
  }

  .top-three {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    padding: 10px;

    .top-three-top {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      font-feature-settings: "kern" on;
    }

    .top-three-bottom {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      margin-top: 10px;
      .top-three-bottom-card {
        padding: 8px 16px;
        flex-grow: 1;
        align-self: stretch;
        background: #b50413;
        text-align: center;
        border-radius: 4px;
        .value {
          font-family: HarmonyOS Sans SC;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          font-feature-settings: "kern" on;
          color: #ffffff;
        }
        .text {
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;
          font-feature-settings: "kern" on;
          color: #ffffff;
        }
      }
    }
  }
}

.checkMore {
  cursor: pointer;
  width: 80px;
}

.playerList {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .play-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    .cover {
      position: relative;
      width: 100%;
      height: 145px;
      background-repeat: no-repeat;
      background-size: cover;

      .status-tags {
        position: absolute;
        font-size: 12px;
        color: #fff;
        padding: 2px 8px;
        top: 2px;
        right: 2px;
        border-radius: 4px;
      }
    }

    .play-card-box {
      padding: 8px 12px 12px 12px;

      .play-card-title {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        min-height: 40px;
      }

      .play-card-psn {
        width: 100%;
        color: #818496;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        margin: 8px 0;
        .mout {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .play-card-study {
        font-size: 14px;
        color: #71b158;
        margin: 8px 0;
      }

      .progress {
        width: 100%;
      }
      .progress-plan {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 20px;
        background: #60b364;
      }
    }
  }
  .play-card:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}
.information {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  .information-card {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    .information-card-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: flex-start;
      .title {
        color: #21252e;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .status {
        font-size: 12px;
        width: max-content;
        white-space: nowrap;
        padding: 2px 5px;
        margin-right: 5px;
        border-radius: 4px;
        color: #fff;
      }
    }
    .information-card-abstract {
      font-size: 12px;
      padding: 10px;
      background: #eff3f2;
      border-radius: 4px;
      margin: 8px 0px;
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    .information-card-bottom {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      .information-card-bottom-time {
        font-size: 12px;
        color: #818496;
      }
      .wordsNum-box {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        color: #818496;
        .wordsNum {
          color: #1677fe;
          margin-right: 5px;
        }
      }
    }
  }
  .information-card:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}
</style>
